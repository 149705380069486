import React, { useState } from "react";
import { Button, FormInput, Text, FormSelect } from "components";
import { validationSchema } from "./demo-form-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const DemoForm = () => {
  const [formMessage, setFormMessage] = useState("");

  const options = [
    { value: "wallet", label: "Wallet" },
    { value: "card services", label: "Card Services" },
    // { value: "virtual account", label: "Virtual Account" },
    { value: "settlements", label: "Settlements" },
    { value: "others", label: "Others" },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const requestDemo = async (data, e) => {
    e.preventDefault();

    const { email, name, company, service, phoneNumber } = data;
    /*try {
      await fetch(
        `https://api.mailgun.net/v3/lists/transform@tech.parkwayprojects.com/members?subscribed=true&address=${email}&name=${name}&company=${company}&vars=${JSON.stringify(
          {
            mobile: phoneNumber,
            company: company,
          }
        )}`,
        {
          method: "POST",
          headers: {
            authorization: `Basic YXBpOmtleS0zYTM5ZjgxOGIxZWJjM2YyYTE1MzNmMThmZDkzMzAyZQ==`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then(res => res.json())
        .then(data => {
          setFormMessage(
            "We got that! Someone from our team will reach to you"
          );
          reset();
        })
        .catch(error => {
          setFormMessage(error?.message);
        });
    } catch (error) {
      setFormMessage("Something went wrong, kindly try again");
    } finally {
      setTimeout(() => {
        setFormMessage("");
      }, 7 * 1000);
    }*/

    const bodyData = {
      Data: {
        Recipients: [
          {
            Value: "reachout@parkway.ng",
            Type: 1,
            Channel: 0,
          },
        ],
        Params: {
          Subject: "Request for Demo on Parkway Rails",
          Sender: "Parkway Rails <no-reply@parkway.ng>",
          Params: `{\"FullName\":\"${name}\",\"EmailAddress\":\"${email}\",\"Company\":\"${company}\",\"Service\":\"${service}\",\"PhoneNumber\":\"${phoneNumber}\"}`,
        },
        Attachments: null,
      },
      Name: "demo.request",
    };

    fetch(`https://business.bank3d.ng/InfoGrid.Pulse/api/pulse/createPulse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "pulse.origin": "Parkway.Default",
      },
      body: JSON.stringify(bodyData),
    })
      .then(data => {
        if (!data.ok) {
          setFormMessage(
            "Oops! An error occurred while sending. Please try again."
          );
        }
        return data.json();
      })
      .then(() => {
        setFormMessage("We got that! Someone from our team will reach to you");
        reset();
      })
      .catch(e => {
        setFormMessage(
          "Oops! An error occurred while sending. Please try again."
        );
      })
      .finally(
        setTimeout(() => {
          setFormMessage("");
        }, 7 * 1000)
      );
  };

  return (
    <form onSubmit={handleSubmit(requestDemo)}>
      <Text
        variant="h4"
        value="Get in touch with us"
        weight={700}
        className="mb-[30px]"
      />

      <FormInput
        register={register("name")}
        error={errors.name?.message}
        placeholder="Full Name"
      />
      <FormInput
        register={register("company")}
        error={errors.company?.message}
        placeholder="Company"
      />
      <FormSelect
        placeholder="Select service"
        options={options}
        register={register("service")}
        error={errors.service?.message}
      />
      <FormInput
        type="email"
        register={register("email")}
        error={errors.email?.message}
        placeholder="Email Address"
      />
      <FormInput
        type="tel"
        register={register("phoneNumber")}
        error={errors.phoneNumber?.message}
        placeholder="Phone Number"
      />

      <Button isLoading={isSubmitting} text="Get in touch" size="large" />

      <Text variant="p16" value={formMessage} className="pt-4" />
    </form>
  );
};

export default DemoForm;
