import React from "react";
import ctl from "@netlify/classnames-template-literals";
import LogoLight from "svgs/parkway-light.svg";
import LogoDark from "svgs/parkway-dark.svg";
import PhoneIcon from "svgs/phone.svg";
import EmailIcon from "svgs/email.svg";
import { Br, PLink, Text } from "components";
import DemoForm from "./demo-form";

const Demo = () => {
  return (
    <div className={pageWrapStyle}>
      <PLink to="/" className={darkLogoStyle}>
        <LogoDark width="149" />
      </PLink>

      <div className={darkSectionStyle}>
        <PLink to="/">
          <LogoLight width="149" className="mt-[47px]" />
        </PLink>

        <div className={textWrapStyle}>
          <Text
            color="secondary-100"
            variant="h2"
            value={
              <>
                Connect With Us
                <Br />
              </>
            }
          />

          <div>
            <span className={contactInfo}>
              <PhoneIcon className={contactIcon} />
              <Text
                color="secondary-100"
                variant="h4"
                value={
                  <>
                    <a href="mailto:suppprt@ppl.zohodesk.com">
                      suppprt@ppl.zohodesk.com
                    </a>
                  </>
                }
                className="max-w-[440px]"
              />
            </span>

            <span className={contactInfo}>
              <EmailIcon className={contactIcon} />
              <Text
                color="secondary-100"
                variant="h4"
                value={
                  <>
                    <a href="tel:02018885013">02018885013</a>,{" "}
                    <a href="tel:+23470007275929">070007275929</a>
                  </>
                }
                className="max-w-[440px]"
              />
              {/* <Text
                color="secondary-100"
                variant="h4"
                value={<>+234-7000-7275-929</>}
                className="max-w-[440px]"
              /> */}
            </span>
          </div>

          {/* <Text
            color="secondary-100"
            variant="p18"
            value={
              <>
                We are your partners to execute whatever idea you have around
                financial services and digital into meaningful products.{" "}
              </>
            }
            className="max-w-[440px]"
          /> */}
        </div>
      </div>

      <div className={lightSectionStyle}>
        <p className={linkStyle} to="/contact-us">
          Need help?{" "}
          <PLink to="/contact-us" className="font-bold">
            Contact us
          </PLink>
        </p>

        <DemoForm />
      </div>
    </div>
  );
};

const pageWrapStyle = ctl(`
  flex
  flex-col
  lg:flex-row
  lg:min-h-[100vh]
`);
const darkSectionStyle = ctl(`
  hidden
  lg:flex
  flex-col
  justify-between
  bg-secondary
  bg-[url('../assets/images/demo-pattern.png')]
  bg-cover
  bg-bottom
  bg-no-repeat	
  xl:w-[603px]
  lg:w-[550px]
  pl-[63px]
`);
const lightSectionStyle = ctl(`
  lg:grow
  flex 
  flex-col 
  items-center 
  justify-center
  relative
  xl:pt-16
`);
const linkStyle = ctl(`
  pt-[68px]
  lg:pt-0
  lg:absolute
  lg:top-[47px]
  lg:self-end
  lg:pr-[43px]
  ml-[-160px]
  md:ml-[-270px]
  mb-3
`);
const darkLogoStyle = ctl(`
  lg:hidden 
  mt-[47px] 
  ml-5
`);
const textWrapStyle = ctl(`
  pb-[10vh]
`);
const contactInfo = ctl(`
  flex
  items-center
  mt-4  
`);
const contactIcon = ctl(`
  w-[30px]
  mr-[10px]  
`);

export { Demo };
