import Seo from "components/layout/seo";
import React from "react";
import { Demo } from "templates/demo";

const DemoPage = () => {
  return (
    <>
      <Seo title="Get Demo" />
      <Demo />
    </>
  );
};

export default DemoPage;
