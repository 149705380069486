import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("Kindly enter your full name"),
  email: yup.string().email().required("Kindly enter your email"),
  company: yup.string().required("Kindly enter your place of work"),
  service: yup.string().required("Kindly select the service you need"),
  phoneNumber: yup
    .number()
    .typeError("Kindly enter a valid phone number")
    .min(11, "Phone number should not be less than 11 characters")
    .required("Kindly enter your phone number"),
});
export { validationSchema };
